<div class="custom-pagination-position">
        <p-paginator styleClass="cstm-pagination" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
                (onPageChange)="onPageChange($event)">
        </p-paginator>
</div>
<div class="container-fluid">
        <app-table-template [columns]="columns" [tableData]="orderRequestList | async" [message]="message"
                [apiFilterCall]="true" (customFilter)="filterOrderRequest($event)"
                (reviewBtnClickEvent)="reviewClick($event)"></app-table-template>
</div>
<div class="custom-pagination-position mt-2">
        <p-paginator styleClass="cstm-pagination" [first]="first" [rows]="rows" [totalRecords]="totalRecords"
                (onPageChange)="onPageChange($event)">
        </p-paginator>
</div>